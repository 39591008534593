<template>
  <div class="signBg">
    <div class="scanSignIn" v-show="showSign" style="display:none;">
      <div class="centerBox">
        <div class="centerInBox">
          <img class="scanSignImg" src="@/assets/img/qdyjcg.png" alt="签到成功" />
          <van-button type="default" @click="btnSign">确定</van-button>
          <div class="content-box">
            <p class="signKc">{{ qddkc_word }}</p>
            <p class="signStatus">{{ qddkc_status }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
import { saveSignRecord } from '@api/wxpx.js'
import { decrypt } from '@/utils/aes.js'
import { allClose } from '@/utils/'
export default {
  name: 'scanSignIn',
  data() {
    return {
      userInfo,
      qddkc_word: this.$route.query.name,
      v: this.$route.query.v,
      qddkc_status: '',
      showSign: true,
      outTime: 20, // 二维码超时上限(秒)=
      k: '' //加密字段
    }
  },
  created() {
    this.qddkc_word = this.$route.query.name
    if (
      Date.parse(new Date()) - parseInt(decrypt(this.k, this.v)) <
        this.outTime * 1000 ||
      this.v === 'v8_pc_no_timestamp' ||
      this.v === 'v8pcnotimestamp'
    ) {
      saveSignRecord({
        mautoid: this.$route.query.mautoid,
        empid: this.userInfo.empid,
        username: this.userInfo.username
      }).then(res => {
        if (res.iserror == 0) {
          // Dialog({ message: res.data[0].info })
          this.qddkc_status = res.data[0].info
          this.showSign = true
        }
      })
    } else {
      this.qddkc_status = '二维码已超时'
    }
  },
  methods: {
    btnSign() {
      this.showSign = false
      allClose()
    }
  }
}
</script>
<style lang="less" scoped>
.signBg {
  width: 100%;
  height: 100%;
  background: #fff;
}
.scanSignIn {
  width: 100%;
  height: 100%;
  background: rgba(20, 17, 17, 0.2);
  .scanSignImg {
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
  .centerBox {
    width: 100%;
    height: 100%;
    position: relative;
    .centerInBox {
      width: 100%;
      height: 900px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    /deep/.van-button {
      padding: 0 1rem;
      position: absolute;
      bottom: 1rem;
      z-index: 99999;
      left: 50%;
      transform: translateX(-50%);
    }
    .content-box {
      top: 310px;
      width: 350px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;
      background: transparent;
    }
    .signKc {
      width: 100%;
      color: rgb(43, 141, 240);
      text-align: center;
      font-size: 26px;
    }
    .signStatus {
      color: rgb(153, 153, 153);
      width: 100%;
      text-align: center;
      font-size: 30px;
      margin-top: 30px;
    }
  }
}
</style>